<template>
  <a-spin :spinning="spinning">
    <div class="preview">
      <div class="option-bar">
        <div class="bar-left">
          <span class="option-botton">协议名称：{{ detailInfo.templateName }}</span>
          <span class="option-botton">签署人：{{ detailInfo.personalName }}</span>
        </div>
      </div>
      <div class="bax" style="margin-top: 10px">
        <div class="templete-manage" ref="tm" id="temp-m">
          <!-- :style="{minWidth: editor ? '350px' : '0'}" -->
          <div class="tm-l drag-vb" style="z-index: 10" id="tml">
            <div v-if="this.templateContent != ''">
              <draggable class="dragArea" :id="'draggable' + (index + 1)" v-for="(item, index) in pdf_pages"
                :key="'cc' + index" tag="div">
                <!-- </draggable> -->
              </draggable>
            </div>
            <div style="height: 100%" v-if="this.templateContent == ''" class="content">
              <iframe style="height: 100%; width: 100%; border: 0" :src="baseFileUrl + pdfTemplateUrl"></iframe>
            </div>
          </div>

          <div class="tm-r" id="scrollbox">
            <div class="b-w" style="margin-top: 0px">
              <div class="seal-title">操作</div>

              <div class="tips" style="color: red">
                注意：填充预览后，所见即所得，请仔细查看协议是否有误，及时修改！
              </div>
              <div class="btn-w">
                <a-button type="primary" size="small" @click="fill">{{
                  l("填充预览")
                }}</a-button>
                <a-popconfirm title="确定要发起签署吗？" ok-text="是" cancel-text="否" @confirm="_sendToSign">
                  <a-button type="primary" size="small">{{
                    l("发起签署")
                  }}</a-button>
                </a-popconfirm>
              </div>
            </div>
            <div class="b-w" style="margin-top: 5px">
              <div tag="div" handle=".seal-box">
                <div class="seal-title">系统变量</div>

                <div class="item-w" v-for="(item, index) in fields" :key="index">
                  <div class="iw-l">
                    <a-tooltip>
                      <template slot="title">
                        {{ item.fieldDisplayName }}:
                      </template>
                      {{ item.fieldDisplayName }}:
                    </a-tooltip>
                  </div>
                  <div class="iw-r">
                    <a-input :disabled="!item.isCanEdit" v-model="item.fieldValue" size="small" allowClear :placeholder="l(
                      item.isCanEdit
                        ? `请输入${item.fieldDisplayName}`
                        : '不可编辑'
                    )
                      " />
                  </div>
                </div>
              </div>
            </div>
            <div class="b-w">
              <div tag="div" handle=".seal-box">
                <div class="seal-title">表单变量</div>
                <div class="tips" style="color: red">
                  注意：表单字段如果在这里填充，如果字段是可以用户填写的，将以最后用户填写的表单值为准，如果需要以此处为准，用户不需要填写，请调整表单的填写规则，改为不允许c端用户填写
                </div>
                <div class="item-w" v-for="(item, index) in detailInfo.formFields" :key="index">
                  <div class="iw-l">
                    <a-tooltip>
                      <template slot="title">
                        {{ item.fieldDisplayName }}:
                      </template>
                      {{ item.fieldDisplayName }}:
                    </a-tooltip>
                  </div>
                  <div class="iw-r">
                    <a-input :disabled="!item.isCanEdit" v-model="item.fieldValue" size="small" allowClear :placeholder="l(
                      item.isCanEdit
                        ? `请输入${item.fieldDisplayName}`
                        : '不可编辑'
                    )
                      " />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tm-c tm-rw" style="display: none" id="tmRW1">
        <div class="pw">
          <div class="seal-title title">将印章拖拽到右边合同当中</div>
          <div class="b-w bw1" id="scrollbox">
            <div :list="seallist" :group="{
              name: 'seal',
              put: false,
              pull: 'clone',
            }" tag="div" handle=".seal-box">
              <div class="re-box isSignbox" v-for="item in seallist" :key="item.id" :id="'sealboxhover' + item.id">
                <!-- https://static.dianmi-north.cn/cesseal.png -->
                <div v-if="item.type == 1" :id="'sealid' + item.id" class="seal-box"
                  :data-outid="'sealboxhover' + item.id">
                  <span class="seal-name">{{ item.name }}</span>
                  <!-- <a-icon
                      type="close-circle"
                      class="isSignbox"
                      :data-outid="'sealboxhover' + item.id"
                    /> -->
                  <img v-if="item.pictureUrls != ''" :src="baseFileUrl + item.pictureUrls" class="isSignbox"
                    :data-outid="'sealboxhover' + item.id" />
                  <span v-if="item.pictureUrls == ''" class="isSignbox personal-sign"
                    :data-outid="'sealboxhover' + item.id">{{ item.name }}</span>
                </div>
                <div v-if="item.type != 1" :id="'sealid' + item.id" class="seal-box"
                  :data-outid="'sealboxhover' + item.id">
                  <!-- <a-icon
                      @click.stop="delSeal(item, index)"
                      type="close-circle"
                      class="isSignbox"
                      :data-outid="'sealboxhover' + item.id"
                    /> -->
                  <span class="personal-sign isSignbox" :data-outid="'sealboxhover' + item.id">{{ item.name }}</span>
                  <!-- https://static.dianmi-north.cn/cesseal.png -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { AppComponentBase, ModalComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import { abpService } from "@/shared/abp";
import draggable from "vuedraggable";
import checkAgeFunc from '@/components/PersonAgeTip/check.js';
let hoverX = 0;
let hoverY = 0;
let distanceX = 0;
let distanceY = 0;
let PDFJS = require("pdfjs-dist");
PDFJS.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

export default {
  name: "preview",
  mixins: [AppComponentBase, ModalComponentBase],
  props: [],
  components: { draggable },
  data () {
    return {
      spinning: false,
      commonService: null,
      mobileService: null,
      submitParams: {
        name: "",
      },
      fields: [],
      templateContent: "",
      pdfTemplateUrl: "",
      basePdfTemplateUrl: "",
      oTemplateContent: "",
      oHeight: "",
      fieldsList: [],
      baseFileUrl: "",
      htmlcontext: "",
      pdf_scale: 1.3, //pdf放大系数
      pdf_pages: [],
      pdf_div_width: "",
      pdf_src: null,
      pageNum: 0,
      scrolltop: 0,
      agreementTemplateId: "",
      detailInfo: {},
      seallist: [],
      oSeallist: [],
      signingMethods: [],
      submitSealList: [],
      fixSeal: [
        {
          id: "singer-",
          name: "个人签名",
          type: 3,
          sort: "",
        },
        {
          id: "agent-1",
          name: "代理人签名1",
          type: 4,
          sort: "",
        },
        {
          id: "agent-2",
          name: "代理人签名2",
          type: 4,
          sort: "",
        },
        {
          id: "agent-3",
          name: "代理人签名3",
          type: 4,
          sort: "",
        },
        {
          id: "agent-4",
          name: "代理人签名4",
          type: 4,
          sort: "",
        },
        {
          id: "agent-5",
          name: "代理人签名5",
          type: 4,
          sort: "",
        },
        {
          id: "agent-6",
          name: "代理人签名6",
          type: 4,
          sort: "",
        },
        {
          id: "agent-7",
          name: "代理人签名7",
          type: 4,
          sort: "",
        },
        {
          id: "agent-8",
          name: "代理人签名8",
          type: 4,
          sort: "",
        },
      ],
    };
  },
  computed: {
    elTMC () {
      return this.$el.querySelector(".tm-c");
    },
    elBW1 () {
      return this.$el.querySelector(".bw1");
    },
    elBW2 () {
      return this.$el.querySelector(".bw2");
    },
    elTMR () {
      return this.$el.querySelector(".tm-r");
    },
    // elTMSUB() {
    //   return this.$el.querySelector(".tm-sub");
    // },
    maxTop () {
      if (this.pdf_pages > 0) {
        let h = document.getElementById("draggable1").offsetHeight;
        return h - 160;
      } else {
        return 945;
      }
    },
    maxLeft () {
      if (this.pdf_pages > 0) {
        let w = document.getElementById("draggable1").offsetWidth;
        return w - 160;
      } else {
        return 600;
      }
    },
  },
  created () {
    //this.fullData(); // 模态框必须,填充数据到data字段
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.mobileService = new CommonServiceProxy(
      AppConsts.mobileApiBaseUrl,
      this.$api
    );
    this.oHeight = window.innerHeight - 200;
    this.baseFileUrl = AppConsts.uploadBaseUrl;
    this.projectAgrementId = this.$route.params.projectagreementId;
    this.personnelId = this.$route.params.projectUserId;
  },
  mounted () {
    this._getSealListByProjectId();
    document
      .getElementById("scrollbox")
      .setAttribute(
        "style",
        "height:" + (document.documentElement.clientHeight - 60) + "px"
      );
    window.addEventListener("scroll", this.scrollevent);
  },
  methods: {
    async _getSignDataSource () {
      this.spinning = true;
      try {
        let res = await this.mobileService.get(
          {
            url: "/api/Unnatural/getSignDataSource",
            params: {
              projectAgreementId: this.projectAgrementId,
              personnelId: this.personnelId,
            },
          },
          {
            WechatAppletTenantId:
              abpService.abp.multiTenancy.getTenantIdCookie() || "",
          }
        );
        if (res.success && res.statusCode === 200) {
          this.fieldsList = res.data;
        } else {
          this.$notification["warning"]({
            message: "提示",
            description: `${res.message}， 无法填充`,
          });
        }
        this._getProjectAgrementSignDetail(res.data.length ? res.data : []);
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    fill () {
      this._speContractFill();
    },
    reset () {
      this.fields.forEach((item) => {
        if (item.isCanEdit) {
          item.fieldValue = "";
        }
      });
      this.templateContent = this.oTemplateContent;
      this.pdfTemplateUrl = this.basePdfTemplateUrl;
    },
    async _sendToSign () {
      this.spinning = true;
      try {
        const params = {
          projectAgreementId: this.projectAgrementId,
          personnelIds: [this.personnelId]
        };
        await checkAgeFunc(params, (res) => {
          if (res) {
            this.realSendToSign();
          }
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.spinning = false;
      }
    },
    async realSendToSign () {
      this.spinning = true;
      let fieldsList = [...this.fields, ...this.detailInfo.formFields];
      let signMethods = [];
      this.submitSealList.map((item) => {
        let _cldom = document.getElementById(item.cloneId);
        let _parentId = _cldom.parentNode.id;
        let _pageNum = _parentId.split("draggable")[1];
        // console.log(_cldom.offsetTop)
        // console.log(_cldom.offsetLeft)
        // console.log(_cldom.parentNode)
        // console.log(_cldom.parentNode.id)

        const obj = {
          id: item.fkId,
          sealName: item.name,
          sort: item.sort,
          keyWord: item.id,
          offsetX: _cldom.offsetLeft,
          offsetY: _cldom.offsetTop,
          pageNum: _pageNum,
          signerType: item.type,
          organizationSealId: item.type == 1 ? item.id : null,
          signMethod: item.signType,
        };
        signMethods.push(obj);
      });
      const opts = {
        userId: this.personnelId,
        templateGuid: this.agreementTemplateId,
        projectAgreementId: this.projectAgrementId,
        signingMethods: signMethods,
        fields: fieldsList.map((v) => {
          return {
            fieldKey: v.fieldKey,
            fieldDisplayName: v.fieldDisplayName,
            fieldValue: this.isCanEdit(v.fieldKey) ? v.fieldValue : null,
          };
        }),
      };
      try {
        let res = await this.commonService.post({
          url: `/api/services/app/UnnaturalPersonProjectAgreement/SendToSign`,
          params: { ...opts },
        });
        this.$notification["success"]({
          message: "提示",
          description: `发起签署成功`,
        });
        setTimeout(() => {
          window.close();
        }, 1500);
      } catch (error) {
        console.log(error);
        this.spinning = false;
      } finally {
        this.spinning = false;
      }
    },
    async _contractPreview () {
      this.spinning = true;
      const opts = {
        userId: this.personnelId,
        templateGuid: this.agreementTemplateId,
        projectAgreementId: this.projectAgrementId,
        fields: [...this.fields, ...this.detailInfo.formFields],
      };
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonProjectAgreement/ContractPreviewV2",
          params: { ...opts },
        });
        window.open(`${AppConsts.uploadBaseUrl}${res}`);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async _speContractFill () {
      this.spinning = true;
      const opts = {
        userId: this.personnelId,
        templateGuid: this.agreementTemplateId,
        projectAgreementId: this.projectAgrementId,
        fields: [...this.fields, ...this.detailInfo.formFields],
      };
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/UnnaturalPersonProjectAgreement/ContractPreviewV2",
          params: { ...opts },
        });
        this.pdfTemplateUrl = res;
        this.spinning = false;
        this.showPdf(res);
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    async _getProjectAgrementSignDetail (fieldsList) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/UnnaturalPersonProjectAgreement/GetProjectAgrementSignDetail",
          params: {
            projectAgrementId: this.projectAgrementId,
            personnelId: this.personnelId,
          },
        });

        if (res.fields.length) {
          this.fields = res.fields.map((item) => {
            let f = null;
            if (fieldsList.length) {
              f = fieldsList.find((v) => v.key === item.fieldKey);
            }
            return {
              ...item,
              isCanEdit: this.isCanEdit(item.fieldKey),
              fieldValue: f ? f.value : item.fieldValue,
            };
          });
        }
        if (res.formFields.length > 0) {
          res.formFields.forEach((value) => {
            let f = null;
            if (fieldsList.length) {
              f = fieldsList.find((v) => v.key === value.fieldKey);
            }
            value.isCanEdit = true;
            value.fieldValue = f ? f.value : value.fieldValue;
          });
        }
        if (res.templateContent) {
          this.templateContent = res.templateContent;
          this.oTemplateContent = res.templateContent;
        }
        this.pdfTemplateUrl = res.pdfTemplateUrl;
        this.basePdfTemplateUrl = res.pdfTemplateUrl;
        this.agreementTemplateId = res.agreementTemplateId;
        this.htmlcontext = res.templateContent;
        if (res.signingMethod.length) {
          this.signingMethods = res.signingMethod;
        }
        this.detailInfo = res;
        this.spinning = false;
        this.preMakePdf();
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    // 获取印章列表
    async _getSealListByProjectId () {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: `/api/services/app/UnnaturalPersonProjectAgreement/GetSealListByProjectId`,
          params: {
            projectId: this.$route.query.projectId,
            agreementGroupType: this.$route.query.type,
          },
        });
        if (res && res.length) {
          this.seallist = res.map((v) => {
            return {
              ...v,
              type: 1,
              picUrl: `${AppConsts.uploadBaseUrl}${v.pictureUrls}`,
            };
          });
          this.seallist = this.seallist.concat(this.fixSeal);
          this.oSeallist = [...this.seallist];
          console.log(this.seallist);
        }
        this.spinning = false;
        this.$nextTick(() => {
          this._getSignDataSource();
        });
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    isCanEdit (type) {
      switch (type) {
        case "organization_name":
          return false;
        case "personnel_realname":
          return false;
        case "phone_number":
          return false;
        case "idcard":
          return false;
        default:
          return true;
      }
    },
    scrollevent () {
      this.scrolltop =
        document.documentElement.scrollTop || document.body.scrollTop;
      document
        .getElementById("scrollbox")
        .setAttribute(
          "style",
          "margin-top:" +
          (this.scrolltop + 5) +
          "px;height:" +
          (document.documentElement.clientHeight - 60) +
          "px"
        );
    },
    _renderPage (num) {
      //渲染pdf页
      const that = this;
      this.pdfDoc.getPage(num).then((page) => {
        let doc = document.getElementById("draggable" + num);
        doc.innerHTML = "";
        let hoverBox = document.createElement("div");
        let numbox = document.createElement("div");
        numbox.innerHTML = "第" + num + "页";
        numbox.setAttribute("style", "position: absolute;right:10px;top:10px");
        hoverBox.setAttribute("id", "canvas-box" + num);
        hoverBox.setAttribute("class", "canvas-box");
        hoverBox.setAttribute("data-num", num);
        let canvas = document.createElement("canvas");
        canvas.setAttribute("class", "pdf-canvas");
        canvas.setAttribute("id", "pdf-canvas" + num);

        let drapbox = document.createElement("pdfcanves");
        let ctx = canvas.getContext("2d");
        let dpr = window.devicePixelRatio || 1;
        let bsr =
          ctx.webkitBackingStorePixelRatio ||
          ctx.mozBackingStorePixelRatio ||
          ctx.msBackingStorePixelRatio ||
          ctx.oBackingStorePixelRatio ||
          ctx.backingStorePixelRatio ||
          1;
        let ratio = 1;
        let viewport = page.getViewport(this.pdf_scale);
        canvas.width = 595 * this.pdf_scale * ratio;
        hoverBox.setAttribute(
          "style",
          "border: 1px #dad4d4 dashed;width:" +
          (canvas.width + 2) +
          "px;position: relative;margin-bottom:10px;"
        );
        canvas.height = 842 * this.pdf_scale * ratio;
        canvas.style.width = 595 * this.pdf_scale95 + "px";
        that.pdf_div_width = 595 * this.pdf_scale + "px";
        canvas.style.height = 842 * this.pdf_scale + "px";
        ctx.setTransform(ratio, 0, 0, ratio, 0, 0);
        let renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };
        page.render(renderContext);
        hoverBox.appendChild(canvas);
        hoverBox.appendChild(numbox);
        drapbox.appendChild(hoverBox);
        doc.appendChild(hoverBox);
        if (this.pdf_pages > num) {
          this._renderPage(num + 1);
        } else {
          console.log(1);
          // this.spinning = false;
          this.$nextTick(() => {
            this.renderCloneSealList(this.signingMethods);
          });
        }
      });
    },
    async preMakePdf () {
      let res = await this.commonService.post({
        url: `/api/services/app/AgreementTemplate/PreMakePdf`,
        params: {
          content: this.htmlcontext,
          generalFieldsOfAgreementContentIds: this.fieldList,
        },
      });
      this.showPdf(res);
    },
    onMove (evt, originalEvent) {
      console.log("move事件", evt);
      console.log("originalEvent:", originalEvent); //鼠标位置
    },
    dragEnd (e) {
      // console.log(e, e.offsetX, e.offsetY)
      let ele = document.getElementById(e.srcElement.id);
      console.log("offsetTop::::", ele.offsetTop);
      console.log("offsetLeft::::", ele.offsetLeft);

      let allbox = document
        .getElementById("tml")
        .getElementsByClassName("seal-box");
      for (let i = 0; i < allbox.length; i++) {
        allbox[i].classList.remove("hide");
      }
      //获取实时鼠标位置
      let x = e.clientX;
      let y = e.clientY;
      // 计算出ele的坐标
      let left = x - distanceX;
      let top = y - distanceY;

      if (left >= this.maxLeft) {
        //当超过右边界
        ele.style.left = this.maxLeft + "px";
      } else if (left < 0) {
        //当超过左边界
        ele.style.left = "0" + "px";
      } else {
        ele.style.left = left + "px";
      }
      if (top >= this.maxTop) {
        //当超过右边界
        ele.style.top = this.maxTop + "px";
      } else if (top < 0) {
        //当超过左边界
        ele.style.top = "0" + "px";
      } else {
        ele.style.top = top + "px";
      }
    },

    dragStart (e) {
      console.log(e);
      let allbox = document
        .getElementById("tml")
        .getElementsByClassName("seal-box");
      for (let i = 0; i < allbox.length; i++) {
        if (e.srcElement.id !== allbox[i].parentNode.id) {
          allbox[i].classList.add("hide");
        }
      }
      //获取鼠标位置
      const x = e.clientX;
      const y = e.clientY;
      //获取draggable的位置
      const offsetX = e.target.offsetLeft;
      const offsetY = e.target.offsetTop;
      // console.log(offsetX, offsetY);
      //保存鼠标悬停处与draggable边界的距离(是全局变量)
      distanceX = x - offsetX;
      distanceY = y - offsetY;
    },
    // 渲染已存在seal
    renderCloneSealList (signingMethods) {
      console.log(signingMethods);
      signingMethods.map((item) => {
        const pdfIdName = `draggable${item.pageNum}`;

        let _domIDname;
        let _keyWord = item.keyWord;

        if (item.flagSignerType == 1) {
        } else if (item.flagSignerType == 3) {
          _keyWord = item.keyWord.split("-")[0] + "-";
        } else if (item.flagSignerType == 4) {
        }
        _domIDname = `sealboxhover${_keyWord}`;
        // console.log('_domIDname::::', _domIDname)
        let sealDom = document.getElementById(_domIDname);

        if (sealDom) {
          let cloneEle = sealDom.cloneNode(true);
          const cloneId = this.returnCloneIdName(cloneEle);
          cloneEle.id = cloneId;
          cloneEle.setAttribute(
            "style",
            "position: absolute;left:" +
            item.offsetX +
            "px;top:" +
            item.offsetY +
            "px;z-index: 10;"
          );
          // cloneEle.setAttribute("data-pageid", item.keyWord);
          cloneEle.classList.add("clone-ele");
          cloneEle.addEventListener("dragend", this.dragEnd, false);
          cloneEle.addEventListener("drag", this.drag, false);
          cloneEle.addEventListener("dragstart", this.dragStart, false);

          // console.log('cloneEle:', cloneEle)

          let targetEle = document.getElementById(pdfIdName);

          targetEle.appendChild(cloneEle);

          const seal = this.seallist.filter((v) => v.id == _keyWord)[0];

          let obj = {
            fkId: item.id,
            cloneId: cloneId,
            delShow: true,
            id: item.keyWord,
            name: seal.name,
            picUrl: `${AppConsts.uploadBaseUrl}${seal.pictureUrls}`,
            pictureUrls: seal.pictureUrls,
            signType: `${item.signMethod}`,
            sort: `${item.sort}`,
            type: Number(item.flagSignerType),
          };

          this.submitSealList.push(obj);
        }
      });
    },
    showPdf (path) {
      console.log(path);

      const loadingTask = PDFJS.getDocument(
        `${AppConsts.uploadBaseUrl}${path}`
      );
      //let loadingTask = PDFJS.getDocument(url);
      loadingTask.promise.then((pdf) => {
        this.pdfDoc = pdf;
        this.pdf_pages = this.pdfDoc.numPages;
        //debugger
        this.$nextTick(() => {
          this._renderPage(1);
        });
      });
    },
    returnCloneIdName (cloneEle) {
      let idName = "";
      for (let g = 1; g < 100; g++) {
        let _idName = `${g}${cloneEle.id}`;
        let _ele = document.getElementById(_idName);
        if (!_ele) {
          idName = _idName;
          break;
        }
      }
      return idName;
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../styles/common.less";

.preview {
  .preview-box {
    padding: 20px;
    font-size: 12px;
    font-weight: bolder;
  }

  .p-w {
    #flex;

    .p-l {
      padding: 0 10px;
      #flex1;
      height: 100%;

      .pc-w {
        overflow-y: scroll;

        border: 1px solid @color-border-gray;
      }

      .fn-w {
        #tar;
        margin-top: 20px;
      }
    }

    .p-r {
      overflow-y: scroll;
      padding: 0 10px;
      width: 280px;

      .t {
        #fw;
        line-height: 40px;
      }

      .item-w {
        #flex;
        #flex-vc;
        margin-bottom: 10px;

        .iw-l {
          #tal;
          padding-right: 5px;
          // width: 30%;
          width: 68px;
          font-size: 14px;
          #text-overflow;
        }

        .iw-r {
          #flex1;
        }
      }

      .btn-w {
        margin-top: 20px;
      }
    }
  }

  #scrollbarStyle;
}

.tips {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  color: #a1a1a1;
}

@import "../../../styles/common.less";

.option-botton {
  cursor: pointer;
  margin: 0 10px;
}

.seal-box {
  text-align: center;
  width: 160px;
  padding: 20px;
  border: 1px red dashed;
  margin: 0 auto;
  margin-bottom: 20px;
  cursor: pointer;

  img {
    width: 120px;
  }

  i {
    color: red;
    cursor: pointer;
    position: absolute;
    top: -7px;
    right: 7px;
  }
}

.personal-sign {
  height: 120px;
  display: block;
  line-height: 120px;
  color: red;
  font-weight: bolder;
}

.drag-vb {
  position: relative;
}

.seal-title {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 12px;
}

.option-bar {
  height: 40px;
  width: 100%;
  display: block;
  border-bottom: solid 1px #f2f2f2;

  .bar-left {
    float: left;
    line-height: 40px;
    margin-left: 20px;
  }

  .bar-right {
    float: right;
    line-height: 40px;
    margin-right: 20px;
  }
}

.a4-box {
  height: 842px;
  width: 595px;
}

.dragArea {
  position: relative;
}

.pdf-canvas {
  position: relative;
}

.re-box {
  position: relative;
}

#hideHtml {
  //margin: 0 ;
  //padding: 0;
  //width: 795px;

  margin-right: 260px;
  text-align: center;
  /* change the margins as you want them to be. */
}

.templete-manage {
  .b-w {
    padding: 10px;
    margin-top: 20px;
    border: 1px #dad4d4 dashed;
  }

  // min-height: 600px;
  position: relative;
  #flex;
  width: 100%;
  min-width: 1558px;

  .tm-l {
    // position: absolute;
    // left: 0;
    // width: 72%;
    padding: 0 10px 0 20px;
    margin: 5px;

    min-width: 775px;

    // #flex1;
    .cp-t-w {
      margin-bottom: 20px;
      margin-top: 20px;
      border: #f2f2f2 1px solid;
      padding: 10px;
    }

    .t-w {
      // background-color: #fff;
    }

    .content-w {
      width: 100%;
      // background-color: #fcf;
      // max-height: 400px;
    }
  }

  .tm-r {
    padding: 0px 10px 100px;
    // position: absolute;
    // top: 0;
    // right: 0;
    // height: 100%;
    height: 1200px;
    // min-width: 250px;
    overflow-y: scroll;
    width: 400px;

    // background-color: @color-white;
    .item-w {
      #flex;
      #flex-vc;
      margin-bottom: 10px;

      .iw-l {
        padding-right: 5px;
        width: 40%;
        font-size: 12px;
        line-height: 30px;
        height: 30px;
      }

      .iw-r {
        width: 70%;
      }
    }

    .day-input {
      // margin-top: 3px;
      margin-bottom: 10px;
    }

    .desc-w {
      padding: 10px;
      border: #f2f2f2 1px solid;

      .title {
        #fs14;
      }

      .tips {
        margin-bottom: 3px;
        font-size: 12px;
        color: #80848f;
      }
    }

    .fields-w {
      .title {
        color: @color-black;
        padding: 2px 0;
        font-size: 12px;
        font-weight: bolder;
      }

      ul {
        #flex;
        #flex-hw;
        #flex-vc;
        padding-left: 0;
        margin-bottom: 0;

        li {
          list-style: none;
          width: 31%;
          margin-right: 1.5%;
          margin-bottom: 1%;

          span {
            #tac;
            #dib;
            padding: 2px 5px;
            #w100;
            #h100;
            border: 1px solid @color-blue;
            #border-radius(3px);
            font-size: 12px;
            background-color: @color-white;
            color: @color-blue;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:hover {
              background-color: @color-blue;
              color: @color-white;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.seal-name {
  cursor: pointer;
  position: absolute;
  left: 5px;
  top: 5px;
  font-size: 12px;
  color: red;
  background: #fff;
}

div::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #e5e5e5;
}

div::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 72%);
  // border-radius: 3px;
}

div::-webkit-scrollbar-thumb:hover {
  background-color: #a1a1a1;
}

div::-webkit-scrollbar-thumb:active {
  background-color: #a1a1a1;
}

div::-webkit-scrollbar-track {
  box-shadow: 0 0 0 gray inset;
}
</style>
